<template>
  <div
    class="flex flex-col items-center my-10"
  >
    <p
      class="font-bold"
    >
      {{ t('ttmt.guest_traveller.messages.login_message') }}
    </p>

    <div class="flex flex-col items-center mt-14">
      <app-button
        class="w-max"
        :label="t('ttmt.common.actions.continue_guest')"
        @click="handleSubmit"
      />

      <app-separator
        class="my-8 w-full"
      />

      <div
        class="flex flex-col items-center space-y-3 w-full"
      >
        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'login', query: route?.query }"
        >
          <app-button
            class="w-max"
            :label="t('ttmt.common.actions.login')"
            @click="navigate"
          />
        </router-link>

        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'register', query: route?.query }"
        >
          <app-button
            class="w-max"
            :label="t('ttmt.common.actions.register')"
            @click="navigate"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { authenticateLogin } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'

const route = useRoute()
const store = useStore()
const { t } = useI18n()

// ---------- FORM SUBMIT ----------

async function handleSubmit() {
  const params = {
    staying_hotel: route?.query?.staying_hotel,
  }

  await authenticateLogin(params)
    .then(async () => {
      await store.dispatch('auth/getAuthenticatedUser')
      await store.dispatch('auth/redirectAuthenticatedUser')
    })
}
</script>
